import { DRAWER_FILTER_TYPE, DEFAULT_WEEK_LIST, DEFAULT_INDICATOR_STATE, DEFAULT_BAR_STATE, DEFAULT_BUY_SELL_OPTION, DEFAULT_CONFIGURE_OPTION, DEFAULT_TIME_OPTION, DEFAULT_ALERT_OPTION, DEFAULT_ALERT_STATE, ALERT_PARAMETER_TYPE, ALERT_FUNCTIONS, ALERT, ALERT_PREFIX, PUSH_NOTIFICATION, SEND_EMAIL, DEFAULT_AUTO_MONEY_OPTION, DEFAULT_MARTINGALE_OPTION, DEFAULT_CUSTOM_CODE_OPTION, DEFAULT_AI_CONFIGURE_OPTION, DEFAULT_CONFIGURE_OPTION_AI } from "../../constants/constants";
import { ACCELERATOR, ADX, ALLIGATOR, AWESOME, ATR, BEARS_POWER, BULLS_POWER, BOLLINGER_BANDS, CCI, DEMARKER, CUSTOM_INDICATOR, ENUMERATION_TYPE, ENVELOPES, FORCE, FRACTALS, GATOR, ICHIMOKU, BWMFI, MOMENTUM, MFI, INDICATOR_DATATYPES, INDICATOR_FUNCTIONS, MOVING_AVERAGE, OSMA, RSI, PARAMETER_TYPE, MACD, OBV, ACCUMULATION, SAR, RVI, STDDEV, WILLIAMS, STOCHASTIC } from "../../constants/constants.Indicator";
import { HIGH, LOW, OPEN, CLOSE, BAR_DATATYPES, BAR_FUNCTIONS } from "../../constants/constants.Bar";
import { BUY_OPTIONS_CODE, SELL_OPTION_CODE } from "../../constants/constants.buysell"
import { CONFIGURE_PARAMETER, TIME_PARAMETER, MARTINGALE_PARAMETER, AUTO_MONEY_PARAMETER, CUSTOM_CODE_PARAMETER, AI_CONFIGURE_PARAMETER } from "../../constants/constants.filter";
import { getStringForNumber } from "../../utils/utils";
import { ONBAR, INIT, TICK } from "../../constants/messages";
import { APIService, requestURLS } from "../../constants/APIConstant";
import moment from "moment-timezone";

let gTimeDefinitionCode = '';
let gAutoMoneyDefinitionCode = '';
let gMartingaleDefinitionCode = '';

export const makeCodeMQ5 = async (generatedCode, workSpaceCode, codeType,aidate) => {
  let code = ''
  let counter = 0;
  let indicatorCounter = 1;
  let alertCounter = 1;
  let timeCount = 1;
  if (codeType === INIT && workSpaceCode[INIT]) {
    if (workSpaceCode[TICK]) {
      const completeBlockCode = workSpaceCode[TICK]
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
      //console.log("object is " + completeBlockCode)
    }
    if (workSpaceCode[ONBAR]) {
      const completeBlockCode = workSpaceCode[ONBAR]
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
      //console.log("object is " + completeBlockCode)
    }
    const completeBlockCode = workSpaceCode[INIT]
    code = await generateBlockCodemq5(generatedCode, completeBlockCode, indicatorCounter, counter, alertCounter, timeCount,aidate)
  }
  else if (codeType === TICK && workSpaceCode[TICK]) {

    if (workSpaceCode[INIT]) {
      const completeBlockCode = workSpaceCode[INIT]
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
    }
    if (workSpaceCode[ONBAR]) {
      const completeBlockCode = workSpaceCode[ONBAR]
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
    }
    const completeBlockCode = workSpaceCode[TICK]

    code = await generateBlockCodemq5(generatedCode, completeBlockCode, indicatorCounter, counter, alertCounter, timeCount,aidate)
  }
  else if (codeType === ONBAR && workSpaceCode[ONBAR]) {
    if (workSpaceCode[INIT]) {
      const completeBlockCode = workSpaceCode[INIT]
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
    }
    if (workSpaceCode[TICK]) {
      const completeBlockCode = workSpaceCode[TICK]
      Object.keys(completeBlockCode).map(blockId => {
        completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR && indicatorCounter++
      })
    }
    const completeBlockCode = workSpaceCode[ONBAR]
    code = await generateBlockCodemq5(generatedCode, completeBlockCode, indicatorCounter, counter, alertCounter, timeCount,aidate)
  }
  return code;
}
const generateBlockCodemq5 = async (generatedCode, completeBlockCode, indicatorCounter, counter, alertCounter, timeCount,aidate) => {
  const functionText = '\nint result;\nresult = 0;\n\n';
  let variable = '';
  let indicatorCode = '';
  let ai_code = ' ';
  let autoMoneyCode = '';
  let martingaleCode = '';
  let alertCode = '';
  let barCode = '';
  let code = '\n\n' + generatedCode;
  const completeBlockKeys = sortByTimeStamp(completeBlockCode)
  let customCodeDefinitionCode = '';
  gAutoMoneyDefinitionCode = '';
  gTimeDefinitionCode = '';
  gMartingaleDefinitionCode = '';
  let automoneyActualCode = '';
  let martingaleActualCode = '';

  await Promise.all(completeBlockKeys.map(async (blockId) => {
    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.WEEK) {
      const [tempVariable, tempCode] = generateCodeForWeekFilter(blockId, counter++, code, completeBlockCode);
      variable = variable + tempVariable;
      code = tempCode
    }


    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.TIME) {
      const [tempInputCode, tempActualCode] = generateCodeForTimeFilter(blockId, timeCount++, code, completeBlockCode);
      gTimeDefinitionCode = tempInputCode;
      code = tempActualCode
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CUSTOM_CODE) {
      code = code.replace(`<<${blockId}>>`, '')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.AUTO_MONEY) {
      automoneyActualCode = 'LotSizeAutomatic=UseAutomaticMoneyManagement;';
      code = code.replace(`<<${blockId}>>`, '')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.MARTINGALE) {
      martingaleActualCode = 'UseMartingale=UseIntelligentMartingale;'
      code = code.replace(`<<${blockId}>>`, '')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CUSTOM_CODE) {
      code = code.replace(`<<${blockId}>>`, completeBlockCode[blockId]['code']['value']);
      customCodeDefinitionCode = generateCodeforCustomCode(completeBlockCode[blockId]['code']);
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.ALERT) {
      const alertType = completeBlockCode[blockId]['code']['name']

      alertCode = generateCodeForAlertFilter(alertType, alertCounter++)
      code = code.replace(`<<${blockId}>>`, alertCode)
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CONFIGUREAI) {
      
      var res = await BucketFileReaderMQL5(completeBlockCode[blockId]?.code,aidate)
      ai_code += res
      code = code.replace(`<<${blockId}>>`, ``)
    }
    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR) {
      const indicatorType = completeBlockCode[blockId]['code']['name']
      let candleValue
      if(completeBlockCode[blockId]['code']['Candle Id']){
        candleValue = completeBlockCode[blockId]['code']['Candle Id']['VALUE']
      }

      const lineMode = completeBlockCode[blockId]['code']['Line Mode'];
      const adxMode = completeBlockCode[blockId]['code']['Adx Mode'];
      const aligatorMode = completeBlockCode[blockId]['code']['Alligator Mode'];
      const chimokuMode = completeBlockCode[blockId]['code']['Ichimoku Mode'];
      const Mode = completeBlockCode[blockId]['code']['Mode'];
      let modeValue;
      
      if (lineMode && lineMode['VALUE']) {
          modeValue = lineMode['VALUE'] === 'MODE_MAIN' ? 0 :  1 ;
      }else if (adxMode && adxMode['VALUE']) {
        modeValue = adxMode['VALUE'] === 'MODE_MAIN' ? 0 : adxMode['VALUE'] === 'ODE_PLUSDI'? 1 : 2;
      }else if (aligatorMode && aligatorMode['VALUE']) {
        modeValue = aligatorMode['VALUE'] === 'MODE_GATORJAW' ? 1 : aligatorMode['VALUE'] === 'MODE_GATORTEETH'? 2 : 3;
      }else if (chimokuMode && chimokuMode['VALUE']) {
        
        let MODE_VALUES = {
        'MODE_TENKANSEN':1,
        'MODE_KIJUNSEN':2,
        'MODE_SENKOUSPANA':3,
        'MODE_SENKOUSPANB':4,
        'MODE_CHIKOUSPAN':5
        }
        modeValue = MODE_VALUES[chimokuMode['VALUE']]
      }else if (Mode && Mode['VALUE']) {
        
        let MODE_VALUES = {
        'MODE_MAIN':0,
        'MODE_UPPER':1,
        'MODE_LOWER':2,
        }
        modeValue = MODE_VALUES[Mode['VALUE']]
      }else{
        modeValue = 0
      }
      

      indicatorCode += generateCodeForIndicatorFiltermq5(indicatorType, indicatorCounter,candleValue,modeValue)
      const res=`\ndouble Indicator${indicatorCounter}= MA_Array${indicatorCounter}BIG_BRACKET_START${candleValue}BIG_BRACKET_ENDSEMICOLON`
      code = res +code.replace(`<<${blockId}>>`, `Indicator${indicatorCounter++}`)

    }
    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CONFIGURE) {
      code = code.replace(`<<${blockId}>>`, '')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.BAR) {
      const barType = completeBlockCode[blockId]['code']['name']
      indicatorCode += generateCodeForBarFilter(barType, indicatorCounter)
      code = code.replace(`<<${blockId}>>`, `Indicator${indicatorCounter++}`)
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.BUY || completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.SELL) {
      const optionsCode = completeBlockCode[blockId]['code']
      const filterType = completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.BUY ? BUY_OPTIONS_CODE : SELL_OPTION_CODE
      code = code.replace(`<<${blockId}>>`, filterType[optionsCode.id])
    }



    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.ASK) {
      code = code.replace(`<<${blockId}>>`, 'Ask')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.BID) {
      code = code.replace(`<<${blockId}>>`, 'Bid')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.PUSH) {
      code = code.replace(`<<${blockId}>>`, '\nSendNotification("Push Notification Test");\n')
    }

    if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.EMAIL) {
      code = code.replace(`<<${blockId}>>`, '\nSendMail("Push Notification Test");\n')
    }

  }))

  console.log('** End Pre Code ***')
  code = code.replace(/Options/g, "true");
  code = code.replace(/\(/g, '');
  code = code.replace(/\)/g, '');
  code = code.replace(/\[/g, '(');
  code = code.replace(/\]/g, ')');
  code = code.replace(/BIG_BRACKET_START/g, '[');
  code = code.replace(/BIG_BRACKET_END/g, ']');
  code = code.replace(/\|\|\>\>/g, '');
  code = code.replace(/\-<<.*?[^\>>]\>>/g, '');
  code = code.replace(/\>\>\ /g, '');
  code = code.replace(/\;/g, '');
  code = code.replace(/\^/g, ';');
  code = code.replace(/SEMICOLON/g, ';');
  code = functionText + '\n' + indicatorCode + '\n' + ai_code + '\n' + variable + '\n' + automoneyActualCode + '\n' + martingaleActualCode + '\n' + code + '\n' + customCodeDefinitionCode + '\n'

  return code
}
export const makeGlobalCodeMQ5 = (workSpaceCode) => {
  let code = '';
  let indicatorCounter = 1;
  let alertCounter = 1;
  let timeCounter = 1;
  let barCounter = 1;
  let latestConfiguration = ''
  let latestConfigurationai = ''
  let indicatorCodeDeclaration = '';
  let indicatorCodeDefinition = '';

  let barCodeDeclaration = '';
  let barCodeDefinition = '';

  let alertCodeDeclaration = '';
  let alertCodeDefinition = '';

  let timeCodeDeclaration = '';
  let timeCodeDefinition = '';

  const workSpaceKeys = sortByCodeType(workSpaceCode)
  workSpaceKeys.map(codeType => {
    const completeBlockCode = workSpaceCode[codeType]
    const completeBlockKeys = sortByTimeStamp(completeBlockCode)
    completeBlockKeys.map(blockId => {
      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CONFIGURE) {
        latestConfiguration = generateGlobalCodeForConfigureFilter(completeBlockCode[blockId]['code'])
        //console.log('block code 0 is ' + completeBlockCode[blockId]['code'])
      }
      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.CONFIGUREAI) {
        latestConfigurationai = generateGlobalCodeForConfigureFilterai(completeBlockCode[blockId]['code'])
        //console.log('block code 0 is ' + completeBlockCode[blockId]['code'])
      }

      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.MARTINGALE) {
        gMartingaleDefinitionCode = generateGlobalCodeForMartingaleFilter(completeBlockCode[blockId]['code'])
      }

      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.AUTO_MONEY) {
        gAutoMoneyDefinitionCode = generateGlobalCodeForAutoMoneyFilter(completeBlockCode[blockId]['code'])
        //automoneyActualCode = 'LotSizeAutomatic=UseAutomaticMoneyManagement;';
      }
     
      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.TIME) {
        const [tempInputCode, tempActualCode] = generateCodeForTimeFilter(blockId, timeCounter++, code, completeBlockCode);
        console.log('tempInputCode new is ' + tempInputCode)
        timeCodeDeclaration += tempInputCode
        timeCodeDefinition += tempActualCode
      }

      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.ALERT) {
        const alertType = completeBlockCode[blockId]['code']['name']
        alertCodeDefinition += generateGlobalCodeForAlertFilter(completeBlockCode[blockId]['code'], alertCounter++)
      }

      if (completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.INDICATOR || completeBlockCode[blockId]['type'] === DRAWER_FILTER_TYPE.BAR) {
        const indicatorType = completeBlockCode[blockId]['code']['name']
        indicatorCodeDeclaration += `\nint  MA_Handle${indicatorCounter}; // ${getStringForNumber(indicatorCounter)} Indicator ${indicatorType}\n`
        indicatorCodeDeclaration += `double MA_Array${indicatorCounter}[]; \n`
        indicatorCodeDefinition += generateGlobalCodeForIndicatorFiltermq5(completeBlockCode[blockId]['code'], indicatorCounter++, completeBlockCode[blockId]['code'].name)
      }
    })
  })

  code ="\n" + latestConfigurationai + "\n" + latestConfiguration + "\n" + gAutoMoneyDefinitionCode + "\n" + gMartingaleDefinitionCode + "\n" + timeCodeDeclaration + "\n" + alertCodeDeclaration + "\n" + indicatorCodeDeclaration + "\n" + indicatorCodeDefinition + "\n" + alertCodeDefinition + "\n"; //+ barCodeDeclaration + "\n" + barCodeDefinition + "\n"
  return code
}

export const BucketFileReaderMQL5 = async (code,date) => {
  const moscowTime = moment.tz("Europe/Moscow").format("YYYY-MM-DDTHH:mm:ss");

  const token= localStorage.getItem('token')
  const formData = new FormData();

  formData.append('symbol_name', code?.Symbol?.value);
  formData.append('timeframe', code?.Timeframe?.value?.split('_')[1]);
  formData.append('date_time', moment(moscowTime).format("YYYY-MM-DDTHH:mm:ss") + "Z");

  let getURL = APIService.dev + requestURLS.DIRECTION;
  try {
    const response = await fetch(getURL, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`, 
      },
      body: formData
    });
  
    if (response.ok) {
      const data = await response.json();
      if(data?.data?.forecast_direction){
        const str_two =  `

int TradeDirection;
string AI_Direction_From_Database = "${data?.data?.forecast_direction}";

if (AI_Direction_From_Database=="UP")
{
TradeDirection = LONG;
}
  
if (AI_Direction_From_Database=="DOWN")
{
TradeDirection = SHORT;
} 
`;
     const final_result = `${str_two}`
     return final_result;
      }

    } else {
      console.error('Failed to fetch:', response.status, response.statusText);
    }
  } catch (error) {
    console.error("Error fetching/processing file:", error);
    throw error; // Propagate the error
  }
};





const sortByCodeType = (workSpaceCode) => {
  return Object.keys(workSpaceCode).sort((a, b) => a.localeCompare(b))
}


const sortByTimeStamp = (completeBlockCode) => {
  return Object.keys(completeBlockCode).sort((a, b) => completeBlockCode[a].timeStamp > completeBlockCode[b].timeStamp ? 1 : -1)
}

const generateGlobalCodeForIndicatorFiltermq5 = (blockCode, counter,blockname) => {
  let code = ''

  const commanRemoveFields = [''];
  const adx_ignore = ['Adx Mode','Applied Price'];
  const iBearsPower = ['Applied Price'];
  const Alligator = ['Alligator Mode'];
  const iBands = ['Mode'];
  const Ichimoku = ['Ichimoku Mode'];
  const ma_ignore = [''];
  const macd_ignore = ['Line Mode'];
  const Shift = ['Shift'];
  const rvi = ['Shift','Line Mode'];
  const STOCHASTIC = ['Shift','Line Mode'];

  const ignore_list = {
    "ADX": adx_ignore,
    "Moving Average": ma_ignore,
    "Bears Power": iBearsPower,
    "Bulls Power": iBearsPower,
    "Alligator": Alligator,
    "Bollinger Bands": iBands,
    "Envelopes": iBands,
    "Fractals": iBands,
    "Gator": iBands,
    "Ichimoku": Ichimoku,
    "MACD": macd_ignore,
    "Force Index": iBearsPower,
    "On Balance Volume": iBearsPower,
    "Accumulation/Distribution": Shift,
    "Parabolic SAR": Shift,
    "Relative Vigor Index": rvi ,
    "Stochastic Oscillator": STOCHASTIC ,
  };

  const removeFields = ignore_list[blockname];
  Object.keys(blockCode).map(key => {

    let fieldType = Object.values(PARAMETER_TYPE).filter(field =>!commanRemoveFields.includes(field.NAME) && !removeFields?.includes(field.NAME) && field.NAME === key)
    fieldType = fieldType.length && fieldType[0]

    const isUserAdjustable = blockCode[key].ADJUSTABLE ? 'input ' : '';
    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.STRING) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      let paramValue = blockCode[key].VALUE
      if (fieldType.NAME === PARAMETER_TYPE.CUSTOM_INDICATOR_PARAMETER.NAME) {
        let tempValue = paramValue.split(",");
        tempValue.map((item, index) => { if (isNaN(Number(item))) tempValue[index] = `\\"${item}\\"` })
        paramValue = tempValue.join(",")
      }
      paramValue = paramValue ? `"${paramValue}"` : 'NULL';
      code += `${isUserAdjustable}string ${paramName}${counter} = ${paramValue}; // ${key} value for ${getStringForNumber(counter)} Indicator\n`
    }

    if (fieldType && (fieldType.DATATYPE === INDICATOR_DATATYPES.INTEGER
      || fieldType.NAME === PARAMETER_TYPE.MODE.NAME || fieldType.NAME === PARAMETER_TYPE.ADX_MODE.NAME || fieldType.NAME === PARAMETER_TYPE.ALLIGATOR_MODE.NAME || fieldType.NAME === PARAMETER_TYPE.ICHI_MODE.NAME || fieldType.NAME === PARAMETER_TYPE.LINE_MODE.NAME)) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      code += `${isUserAdjustable}int ${paramName}${counter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(counter)} Indicator\n`
    }

    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.DOUBLE) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      code += `${isUserAdjustable}double ${paramName}${counter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(counter)} Indicator\n`
    }

    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.ENUM) {
      const typeName = ENUMERATION_TYPE[fieldType.NAME];
      if (!typeName) return;
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key;
      code += `${isUserAdjustable}${typeName} ${paramName}${counter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(counter)} Indicator\n`
    }
  })
// console.log('*-*-*-*',code);
  return code + '\n'
}

const generateGlobalCodeForConfigureFilter = (blockCode) => {
  return `\ninput double Take_Profit = ${blockCode[CONFIGURE_PARAMETER.TAKE_PROFIT.NAME]}; // Take Profit\ninput double Stop_Loss = ${blockCode[CONFIGURE_PARAMETER.STOP_LOSS.NAME]}; // Stop Loss\ninput double Lots = ${blockCode[CONFIGURE_PARAMETER.LOTS.NAME]}; // Lots\n\ndouble Ask=NormalizeDouble(SymbolInfoDouble(_Symbol,SYMBOL_ASK),_Digits);\ndouble Bid=NormalizeDouble(SymbolInfoDouble(_Symbol,SYMBOL_BID),_Digits);`
}


// for AI engine
const generateGlobalCodeForConfigureFilterai = (blockCode) => {
 return ``
  // return ` \nstring Symbol0 = "${blockCode?.Symbol?.value}"; // Symbol value for First Indicator\n ENUM_TIMEFRAMES Timeframe0 = ${blockCode?.Timeframe?.value}; // Timeframe value for First Indicator\n\n`
}

const generateGlobalCodeForMartingaleFilter = (blockCode) => {
  return `\ninput bool UseIntelligentMartingale = ${blockCode[MARTINGALE_PARAMETER.USE_INTELLIGENT_MARTINGALE.NAME]}; // Intelligent Martingale\ninput double MartingaleMultiplier = ${blockCode[MARTINGALE_PARAMETER.MARTINGALE_MULTIPLIER.NAME]}; // Martingale Multiplier\ninput double MaximumMartingaleLotSize = ${blockCode[MARTINGALE_PARAMETER.MAX_MARTINGALE_LOT_SIZE.NAME]}; // MAximum Martingale Lot Size\n\n`
}

const generateCodeforCustomCode = (blockCode) => {
  return `\n ${blockCode[CUSTOM_CODE_PARAMETER.CUSTOM_CODE.NAME].VALUE} // Custom Code\n\n`
}


const generateGlobalCodeForAutoMoneyFilter = (blockCode) => {
  return `\ninput bool UseAutomaticMoneyManagement = ${blockCode[AUTO_MONEY_PARAMETER.USE_AUTO_MONEY_MANAGEMENT.NAME]}; // Use Auto Money Management\ninput double TradeRiskPercentage = ${blockCode[AUTO_MONEY_PARAMETER.TRADE_RISK_PERCENTAGE.NAME]}; // Trade Risk Percentage\n\n`
}

const generateGlobalCodeForTimeFilter = (blockCode, counter) => {
  console.log('block 0 is ' + blockCode[TIME_PARAMETER.TIME.NAME]);
  const time_input_code = `\ninput string Time${counter} = aa${blockCode[TIME_PARAMETER.TIME.NAME]}aa; // Time \n\n`
  const time_actual_code = ''
  return [time_input_code, time_actual_code]
}


const generateCodeForAlertFilter = (alertName, alertCounter) => {
  let code = '';
  const functionName = ALERT_FUNCTIONS[alertName]
  if (alertName === ALERT) {
    const params = [
      ALERT_PARAMETER_TYPE.ALERT_MESSAGE.NAME
    ]
    return generateFunctionCodeForAlertBlock(params, alertCounter, functionName)
  }
  if (alertName === PUSH_NOTIFICATION) {
    const params = [
      ALERT_PARAMETER_TYPE.ALERT_MESSAGE.NAME
    ]
    return generateFunctionCodeForAlertBlock(params, alertCounter, functionName)
  }

  if (alertName === SEND_EMAIL) {
    const params = [
      ALERT_PARAMETER_TYPE.ALERT_MESSAGE.NAME,
      ALERT_PARAMETER_TYPE.ALERT_MESSAGE.NAME
    ]
    return generateFunctionCodeForAlertBlock(params, alertCounter, functionName)
  }
}

const generateCodeForIndicatorFiltermq5 = (indicatorName, counter,candleValue,modeValue) => {

  let code = '';
  const functionName = INDICATOR_FUNCTIONS[indicatorName]
  if (indicatorName === ACCELERATOR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === ACCUMULATION) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME,PARAMETER_TYPE.APPLIED_VOLUM.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === ADX) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === ALLIGATOR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.JAW_PERIOD.NAME, PARAMETER_TYPE.JAW_SHIFT.NAME,
      PARAMETER_TYPE.TEETH_PERIOD.NAME, PARAMETER_TYPE.TEETH_SHIFT.NAME, PARAMETER_TYPE.LIPS_PERIOD.NAME, PARAMETER_TYPE.LIPS_SHIFT.NAME,
      PARAMETER_TYPE.METHOD.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === AWESOME) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === ATR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === BEARS_POWER) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === BULLS_POWER) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === BOLLINGER_BANDS) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.SHIFT.NAME,PARAMETER_TYPE.DEVIATION.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === CCI) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === DEMARKER) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME 
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === ENVELOPES) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.SHIFT.NAME, PARAMETER_TYPE.METHOD.NAME,  PARAMETER_TYPE.APPLIED_PRICE.NAME,
      PARAMETER_TYPE.DEVIATION.NAME]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === FORCE) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.METHOD.NAME, PARAMETER_TYPE.APPLIED_VOLUM.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === FRACTALS) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === GATOR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.JAW_PERIOD.NAME, PARAMETER_TYPE.JAW_SHIFT.NAME,
      PARAMETER_TYPE.TEETH_PERIOD.NAME, PARAMETER_TYPE.TEETH_SHIFT.NAME, PARAMETER_TYPE.LIPS_PERIOD.NAME, PARAMETER_TYPE.LIPS_SHIFT.NAME,
      PARAMETER_TYPE.METHOD.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === ICHIMOKU) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.TENKANSEN_PERIOD.NAME,
      PARAMETER_TYPE.KIJUNSEN_PERIOD.NAME, PARAMETER_TYPE.SENKOUSPANB_PERIOD.NAME,
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === BWMFI) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.APPLIED_VOLUM.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === MOMENTUM) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME 
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === MFI) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,  PARAMETER_TYPE.APPLIED_VOLUM.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === OBV) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME,  PARAMETER_TYPE.APPLIED_VOLUM.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === MOVING_AVERAGE) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.SHIFT.NAME, PARAMETER_TYPE.METHOD.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME,
    ] 
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === OSMA) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.FAST_EMA_PERIOD.NAME,
      PARAMETER_TYPE.SLOW_EMA_PERIOD.NAME, PARAMETER_TYPE.SIGNAL_PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME 
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === MACD) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.FAST_EMA_PERIOD.NAME,
      PARAMETER_TYPE.SLOW_EMA_PERIOD.NAME, PARAMETER_TYPE.SIGNAL_PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME 
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === RSI) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.APPLIED_PRICE.NAME 
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === CUSTOM_INDICATOR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CUSTOM_INDICATOR_NAME.NAME,
      PARAMETER_TYPE.CUSTOM_INDICATOR_PARAMETER.NAME, PARAMETER_TYPE.BUFFER_MODE.NAME 
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === HIGH) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === LOW) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === OPEN) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === CLOSE) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === SAR) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.STEP.NAME,PARAMETER_TYPE.MAXIMUM.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === RVI) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME,PARAMETER_TYPE.PERIOD.NAME
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === STDDEV) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.PERIOD.NAME,
      PARAMETER_TYPE.SHIFT.NAME, PARAMETER_TYPE.METHOD.NAME, PARAMETER_TYPE.APPLIED_PRICE.NAME,
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === WILLIAMS) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME,PARAMETER_TYPE.PERIOD.NAME 
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  if (indicatorName === STOCHASTIC) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME,
      PARAMETER_TYPE.TIMEFRAME.NAME,
      PARAMETER_TYPE.K_PERIOD.NAME,
      PARAMETER_TYPE.D_PERIOD.NAME,
      PARAMETER_TYPE.SLOWING.NAME,
      PARAMETER_TYPE.METHOD.NAME,
      PARAMETER_TYPE.PRICE_STOCHASTIC_FIELD.NAME,
    ]
    return generateFunctionCodeForIndicatorBlock(params, counter, functionName, candleValue,modeValue)
  }
  return code
}


const generateCodeForBarFilter = (barName, counter) => {
  let code = '';
  const functionName = BAR_FUNCTIONS[barName]
  if (barName === HIGH) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForBarBlock(params, counter, functionName)
  }
  if (barName === LOW) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForBarBlock(params, counter, functionName)
  }
  if (barName === OPEN) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForBarBlock(params, counter, functionName)
  }
  if (barName === CLOSE) {
    const params = [
      PARAMETER_TYPE.SYMBOL.NAME, PARAMETER_TYPE.TIMEFRAME.NAME, PARAMETER_TYPE.CANDLE_ID.NAME
    ]
    return generateFunctionCodeForBarBlock(params, counter, functionName)
  }

  return code
}

const generateGlobalCodeForAlertFilter = (blockCode, alertCounter) => {
  let code = ''
  Object.keys(blockCode).map(key => {
    let fieldType = Object.values(ALERT_PARAMETER_TYPE).filter(field => field.NAME === key)
    fieldType = fieldType.length && fieldType[0]
    const isUserAdjustable = blockCode[key].ADJUSTABLE ? 'input ' : '';
    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.STRING) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      let paramValue = blockCode[key].VALUE

      paramValue = paramValue ? `"${paramValue}"` : 'NULL';
      code += `${isUserAdjustable}string ${paramName}${alertCounter} = ${paramValue}; // ${key} value for ${getStringForNumber(alertCounter)} Alert\n`
    }

    if (fieldType && (fieldType.DATATYPE === INDICATOR_DATATYPES.INTEGER
      || fieldType.NAME === PARAMETER_TYPE.MODE.NAME)) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      code += `${isUserAdjustable}int ${paramName}${alertCounter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(alertCounter)} Alert\n`
    }

    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.DOUBLE) {
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key
      code += `${isUserAdjustable}double ${paramName}${alertCounter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(alertCounter)} Alert\n`
    }

    if (fieldType && fieldType.DATATYPE === INDICATOR_DATATYPES.ENUM) {
      const typeName = ENUMERATION_TYPE[fieldType.NAME];
      if (!typeName) return;
      const paramName = typeof key === 'string' ? key.replace(/\s/g, '') : key;
      code += `${isUserAdjustable}${typeName} ${paramName}${alertCounter} = ${blockCode[key].VALUE}; // ${key} value for ${getStringForNumber(alertCounter)} Alert\n`
    }
  })
  return code + '\n'
}


const generateFunctionCodeForBarBlock = (parameter, counter, functionName) => {
  let code = `Indicator${counter} = ${functionName}(`
  parameter.map(param => code += `${param.replace(/\s/g, '')}${counter},`)
  return code.substring(0, code.length - 1) + ');\n'
}


const generateFunctionCodeForIndicatorBlock = (parameter, counter, functionName,candleValue,modeValue) => {
  let timer_code = counter === 1 ? `\nEventSetTimer(60);` : "";
  let Series = `\nArraySetAsSeries(MA_Array${counter},true);` ;
  let Buffer = `\nCopyBuffer(MA_Handle${counter},${modeValue},0,${candleValue > 3 ? Number(candleValue)+1:3},MA_Array${counter});` ;
  let code = `${timer_code}\nMA_Handle${counter} = ${functionName}(`;
  parameter.map((param) => (code += `${param.replace(/\s/g, "")}${counter},`));
  return code.substring(0, code.length - 1) + ");\n"+`${Series}\n${Buffer}\n`;
}

const generateFunctionCodeForAlertBlock = (parameter, alertCounter, functionName) => {
  let code = `${functionName}[`
  parameter.map(param => code += `${param.replace(/\s/g, '')}${alertCounter},`)
  return code.substring(0, code.length - 1) + ']'
}

const generateCodeForWeekFilter = (blockId, index, code, completeBlockCode) => {
  const variable = variableData(completeBlockCode[blockId]['code'], index) + '\n'
  const blockCode = code.replace(`Options-<<${blockId}`, collateData(completeBlockCode[blockId]['code'], index))
  return [variable, blockCode]
}


const generateCodeForTimeFilter = (blockId, index, code, completeBlockCode) => {
  const temp1 = completeBlockCode[blockId]['code'];
  const temp2 = temp1[TIME_PARAMETER.TIME.NAME].VALUE;
  const temp3 = index;
  const time_actual_code = code.replace(`Options-<<${blockId}`, 'TimeCurrent[] >= StrToTime[TimeToStr[TimeCurrent[] , TIME_DATE] + " " + Time' + temp3 + ']')
  const time_input_code = `\ninput string Time${index} = "${temp2}"; // Time \n\n`
  return [time_input_code, time_actual_code]
}


const variableData = (singleWeek, num) => {
  let a = "";
  Object.keys(singleWeek).map((index) => (
    a = a + '\nbool ' + 'Week_' + num + singleWeek[index].name + ' = ' +
    (singleWeek[index].action === 'True' ? true : false) + ';'
  ))
  return a;
}

const collateData = (singleWeek, num) => {
  let a = "";

  Object.keys(singleWeek).map((index) => (

    a = a + ' [DayOfWeek[]==' + index + ' && ' + 'Week_' + num + singleWeek[index].name + ' == ' + true + ']' + '||'
  ))
  return a;
}
