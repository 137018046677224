/**
 * @fileoverview Workspace window for blockly blocks
 */
import "./workspaceWindow.css";
import "./customBlocks";
import CPrompt from "../CPrompt";
import { Button, Input, Modal, Row, message, Select, Divider } from "antd";
import {
  CANCEL,
  DELETE,
  ERROR,
  TICK,
  EX4,
  ONBAR,
  INIT,
  MODAL_DELETE_ROBOT,
  MODAL_HEADER_CONFIRMATION,
  MODAL_HEADER_SAVE,
  MODAL_HEADER_UNSAVED,
  MODAL_HEADER_UNSAVED_CONTENT,
  MQ4,
  PROCEED_ANYWAY,
  SAVE,
  SILVER,
  UNTITLED_ROBOT,
  WORKSPACE_CREATED,
  XML_INITAL_LEN,
  LOADER,
  BRONZE,
  SUCCESS,
  GOLD,
} from "../../constants/messages";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { DRAWER_FILTER_TYPE, FREE_TRAIL } from "../../constants/constants";
import {
  GlobalStates,
  deleteWorkspace,
  getAllWorkspaceList,
  getWorkspace,
  postWorkspace,
  saveFile,
} from "../../actions/workspaceActions";
import Blockly from "blockly";
import ConfigFiles from "./initContent";
import ReactBlocklyComponent from "react-blockly/dist-modules";
import { connect } from "react-redux";
import img from "../../assets/download.svg";
import { getDefaultDrawer, makeCode, makeGlobalCode } from "./utils";
import parseWorkspaceXml from "react-blockly/src/BlocklyHelper";
import WeekDrawer from "../commonComponent/drawers/weekDrawer";
import DrawerBlock from "../commonComponent/drawers/drawerBlock";
import IndicatorDrawer from "../commonComponent/drawers/indicatorDrawer";
import MartingaleDrawer from "../commonComponent/drawers/martingaleDrawer";
import AutoMoneyDrawer from "../commonComponent/drawers/autoMoneyDrawer";
import BarDrawer from "../commonComponent/drawers/barDrawer";
import BuySellDrawer from "../commonComponent/drawers/buySellDrawer";
import { isEmpty } from "../../utils/utils";
import ConfigureDrawer from "../commonComponent/drawers/configureDrawer";
import ConfigureDrawerai from "../commonComponent/drawers/configureDrawerai";
import AlertDrawer from "../commonComponent/drawers/alertDrawer";
import TimeDrawer from "../commonComponent/drawers/timeDrawer";
import CustomCodeDrawer from "../commonComponent/drawers/customCodeDrawer";
import { makeCodeMQ5, makeGlobalCodeMQ5 } from "./utilsbackup";
import lightTheme from "./BlocklyTheme";
import moment from "moment";

const { Option } = Select;
const { BlocklyEditor } = ReactBlocklyComponent;

/** @type {'IDLE' | 'CHANGED'} */
let WORKSPACE_STATE = "IDLE";

function WorkspaceWindow(props) {
  const [toolboxCategories] = useState(
    parseWorkspaceXml(ConfigFiles.INITIAL_TOOLBOX_XML)
  );

  // states for blocks
  const [loader, setloader] = useState({
    mq4: false,
    ex4: false,
    mq5: false,
    ex5: false,
  });
  const [currentBlockId, setCurrentBlockId] = useState("");
  const [aidate, setaidate] = useState(new Date());
  const [currentBlockCode, setCurrentBlockCode] = useState({});
  const [completeBlockCode, setCompleteBlockCode] = useState({});
  const [currentFilterType, setCurrentFilterType] = useState("");
  const [tempBlockCode, setTempBlockCode] = useState({});
  const [deletedCodeState, setDeletedCodeState] = useState({});
  const [workspaceCode, setWorkspaceCode] = useState({});
  const [l, setl] = useState(false);

  // states for drawer
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerHeader, setDrawerHeader] = useState("");
  const [currentRobotId, setCurrentRobotId] = useState("");

  // states for workspace window
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [generatedXml, setGeneratedXml] = useState("");
  const [currentCodeType, setCurrentCodeType] = useState(TICK);
  // states for visiblitiy of action modals
  const [modalSaveVisible, setModalSaveVisible] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [modalUnsavedVisible, setModalUnsavedVisible] = useState(false);

  // states for drawerData
  const [indicatorType, setIndicatorType] = useState("");
  const [barType, setBarType] = useState("");
  const [alertType, setAlertType] = useState("");

  // State to check if event is triggered after duplication
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [isCodeChange, setIsCodeChange] = useState(false);
  const [toogleValue, setToogleValue] = useState("");
  const [downloadFile, setdownloadFile] = useState("");

  useEffect(() => {
    props.getAllWorkspaceList({
      accessToken: localStorage.getItem("token"),
    });

    // Change default duplication callback
    Blockly.ContextMenu.blockDuplicateOption = function (block) {
      const isDuplicatable = block.isDuplicatable();
      return {
        text: Blockly.Msg.DUPLICATE_BLOCK,
        enabled: isDuplicatable,
        callback: () => {
          setIsDuplicated(true);
          Blockly.duplicate(block);
        },
      };
    };
  }, []);

  /**
   * @param {string} id
   * @description Finds ids of all child blocks
   */
  const getChildBlocksIds = (id) => {
    const rootBlock = Blockly.getMainWorkspace().getBlockById(id);
    const ids = [];

    /**
     * @param {Blockly.Block} block
     * @description Recursievly traversals all the children
     */
    const getChildBlocksIdsRecursievly = (block) => {
      if (block.childBlocks_.length === 0) {
        ids.push(block.id);
      } else {
        for (const childBlock of block.childBlocks_) {
          ids.push(block.id);
          getChildBlocksIdsRecursievly(childBlock);
        }
      }
    };

    getChildBlocksIdsRecursievly(rootBlock);
    return ids;
  };

  /**
   * @param {Event} event events for blockly workspace
   * @description handles on click event for blocks
   */
  const onBlocklyClickEvent = (event) => {
    const { accessToken } = props;

    if (event.type === "ui") {
      if (event.element === "click" && event.blockId) {
        initializeStatesOnBlocklyEvents(event);
      } else if (
        event.element === "selected" &&
        event.oldValue &&
        event.newValue
      ) {
        if (!isDuplicated) return;

        const oldValues = getChildBlocksIds(event.oldValue);
        const newValues = getChildBlocksIds(event.newValue);
        setCompleteBlockCode((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState));

          newValues.forEach((id, index) => {
            newState[id].code = JSON.parse(
              JSON.stringify(newState[oldValues[index]].code)
            );
          });

          return newState;
        });
        setIsDuplicated(false);
        Blockly.getMainWorkspace().getBlockById(event.newValue).unselect();
      }
    } else if (
      event.type === Blockly.Events.BLOCK_CHANGE &&
      event.element === "field" &&
      event.blockId
    ) {
      const block = Blockly.getMainWorkspace().getBlockById(event.blockId);
      if (block.type === DRAWER_FILTER_TYPE.INDICATOR) {
        initializeStatesOnBlocklyEvents(event);
        setOpenDrawer(false);
        setCurrentBlockCode(getDefaultDrawer(block.type));
      } else if (block.type === DRAWER_FILTER_TYPE.BAR) {
        initializeStatesOnBlocklyEvents(event);
        setOpenDrawer(false);
        setCurrentBlockCode(getDefaultDrawer(block.type));
      } else if (block.type === DRAWER_FILTER_TYPE.ALERT) {
        initializeStatesOnBlocklyEvents(event);
        setOpenDrawer(false);
        setCurrentBlockCode(getDefaultDrawer(block.type));
      }
    } else if (event.type === Blockly.Events.BLOCK_CREATE && event.ids) {
      const defaultCodeBlock = {};
      event.ids.forEach((blockId) => {
        const block = Blockly.getMainWorkspace().getBlockById(blockId);
        if (deletedCodeState[blockId]) {
          defaultCodeBlock[blockId] = JSON.parse(
            JSON.stringify(deletedCodeState[blockId])
          );
          const tempDeletedCodeState = JSON.parse(
            JSON.stringify(deletedCodeState)
          );
          delete tempDeletedCodeState[blockId];
          setDeletedCodeState({ ...tempDeletedCodeState });
        } else {
          defaultCodeBlock[blockId] = {
            timeStamp: completeBlockCode[blockId]
              ? completeBlockCode[blockId]["timeStamp"]
              : performance.now(),
            type: block?.type,
            code: completeBlockCode[blockId]
              ? JSON.parse(JSON.stringify(completeBlockCode[blockId]["code"]))
              : block?.type === "indicator_filter"
              ? getDefaultDrawer(block.type)[
                  block.getFieldValue("Select Indicator")
                ]
              : block?.type === "Alert"
              ? getDefaultDrawer(block.type)[
                  block.getFieldValue("Select Alert Type")
                ]
              : block?.type === "bar_filter"
              ? getDefaultDrawer(block.type)[
                  block.getFieldValue("Select Bar Type")
                ]
              : getDefaultDrawer(block.type),
          };
        }
      });
      setCompleteBlockCode({ ...completeBlockCode, ...defaultCodeBlock });
    } else if (event.type === Blockly.Events.BLOCK_DELETE && event.ids) {
      let tempCodeBlock = JSON.parse(JSON.stringify(completeBlockCode));

      let tempDeletedCodeBlockState = JSON.parse(
        JSON.stringify(deletedCodeState)
      );
      event.ids.map((blockId) => {
        if (tempCodeBlock[blockId]) {
          tempDeletedCodeBlockState[blockId] = JSON.parse(
            JSON.stringify(tempCodeBlock[blockId])
          );
        }
        delete tempCodeBlock[blockId];
      });

      if (WORKSPACE_STATE === "CHANGED") {
        // tempCodeBlock = {};
        WORKSPACE_STATE = "IDLE";
      }
      // console.log('tempCodeBlockafter',tempCodeBlock);
      // console.log('setcode3',completeBlockCode);
      setCompleteBlockCode({ ...tempCodeBlock });
      setDeletedCodeState({ ...tempDeletedCodeBlockState });
    }
    Blockly.getMainWorkspace().removeChangeListener(onBlocklyClickEvent);
  };

  /**
   * @param {Event} event blockly events
   * @description initializes states corresponding to blockly events
   */
  const initializeStatesOnBlocklyEvents = (event) => {
    const block = Blockly.getMainWorkspace().getBlockById(event.blockId);
    const data = completeBlockCode[event.blockId]
      ? JSON.parse(JSON.stringify(completeBlockCode[event.blockId]["code"]))
      : getDefaultDrawer(block.type);

    const drawerName =
      block && block.inputList[0].sourceBlock_.inputList[0].fieldRow[0].value_;
    setDrawerHeader(drawerName);
    setCurrentBlockId(event.blockId);
    setCurrentFilterType(block.type);
    setCurrentBlockCode({ ...data });
    setOpenDrawer(true);
    if (block.type === DRAWER_FILTER_TYPE.INDICATOR) {
      setIndicatorType(drawerName);
    } else if (block.type === DRAWER_FILTER_TYPE.BAR) {
      setBarType(drawerName);
    } else if (block.type === DRAWER_FILTER_TYPE.ALERT) {
      setAlertType(drawerName);
    }
  };

  useEffect(() => {

    if (props.payment?.mode === BRONZE  || !props.payment?.mode ||props.payment?.mode === 'free') {
      const element =
        document.getElementById("blockly-4") ||
        document.getElementById("blockly-h") ||
        document.getElementById("blockly-u") ||
        document.getElementById("blockly-1k") ||
        document.getElementById("blockly-17");
      if (element) {
        const blocklyElement =
          document.getElementById("blockly-4") ||
          document.getElementById("blockly-h") ||
          document.getElementById("blockly-u") ||
          document.getElementById("blockly-1k") ||
          document.getElementById("blockly-17");
        if (blocklyElement) {
          const elementsWithClass =
            blocklyElement.querySelectorAll(".blocklyTreeRow");
          elementsWithClass.forEach((element) => {
            element.style.opacity = 0.5;
          });
        }

        const disableClick = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };

        blocklyElement.addEventListener("click", disableClick, true);

        // Cleanup function to remove the event listener
        return () => {
          blocklyElement.removeEventListener("click", disableClick, true);
        };
      }
    }
  }, []);

  /**
   * @param {string} key contains selected workspace
   * @description handles change in workspace
   */
  const handleWorkspaceChange = (key) => {
    WORKSPACE_STATE = "CHANGED";
    setCompleteBlockCode({});
    const { accessToken } = props;
    if (checkIfCodeChanged()) {
      onSaveClick();
      return;
    }
    props.getWorkspace({
      id: key,
      accessToken,
      callback: (res, data) => {
        // converts the xml text into xml dom
        if (res === "success") {
          setCurrentName(data.name);
          setName(data.name);
          setCurrentRobotId(key);
          setCurrentCodeType(
            data.tick_xml ? TICK : data.init_xml ? INIT : ONBAR
          );
          setCompleteBlockCode({});
          Blockly.getMainWorkspace().clear();
          if (data.tick_xml) {
            setCompleteBlockCode(data.tick_options);
            const xml = Blockly.Xml.textToDom(data.tick_xml);
            Blockly.Xml.clearWorkspaceAndLoadFromXml(
              xml,
              Blockly.getMainWorkspace()
            );
          } else {
            if (data.init_xml) {
              setCompleteBlockCode(data.init_options);
              const xml = Blockly.Xml.textToDom(data.init_xml);
              Blockly.Xml.clearWorkspaceAndLoadFromXml(
                xml,
                Blockly.getMainWorkspace()
              );
            } else {
              if (data.bar_xml) {
                setCompleteBlockCode(data.bar_options);
                const xml = Blockly.Xml.textToDom(data.bar_xml);
                Blockly.Xml.clearWorkspaceAndLoadFromXml(
                  xml,
                  Blockly.getMainWorkspace()
                );
              }
            }
          }
        }
      },
    });
  };

  /**
   * @param {string} key week filter option value
   * @description temporary block code for week filter
   */
  const handleChange = (key) => {
    let tempOptions = currentBlockCode;
    tempOptions[key.split("-")[1]] = {
      name: key.split("-")[0],
      action: key.split("-")[2],
    };
    setTempBlockCode({ ...tempOptions }); //Only sets the temp options and update only when update is clicked
    setIsCodeChange(true);
  };

  /**
   * @param {object} workspace blockly workspace object
   * @description handles block editor workspace when work space changes
   */
  const workspaceDidChange = (workspace) => {
    workspace.addChangeListener(onBlocklyClickEvent, workspace);
    const xml = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace));
    setGeneratedXml({ ...generatedXml, [currentCodeType]: xml });
  };

  /**
   * @param {string} type type of code event (On Tick, On Init ...)
   * @description generate workspace code for corresponding code type
   */
  const generateCodeForXmlType = (type) => {
    const data = generatedXml[type];

    if (data) {
      const xml = Blockly.Xml.textToDom(data);
      const workspace = new Blockly.Workspace();
      Blockly.Xml.domToWorkspace(xml, workspace);
      return Blockly.JavaScript.workspaceToCode(workspace);
    }
    return "";
  };

  /**
   * @description handles on save action
   */
  const onSave = async (action, type) => {
    setl(true);
    setIsCodeChange(false);
    let data = {};
    let data_mq5 = {};
    if (workspaceCode[TICK] && currentCodeType === TICK) {
      const code = generateCodeForXmlType(TICK);

      data = {
        name: name,
        tick_xml: generatedXml[TICK],
        tick_options: workspaceCode[TICK],
        tick_code: await makeCode(code, workspaceCode, TICK, aidate),
      };
      data_mq5 = {
        name: name,
        mt5_tick_xml: generatedXml[TICK],
        mt5_tick_options: workspaceCode[TICK],
        mt5_tick_code: await makeCodeMQ5(code, workspaceCode, TICK, aidate),
      };
    } else if (workspaceCode[INIT] && currentCodeType === INIT) {
      const code = generateCodeForXmlType(INIT);
      data = {
        name: name,
        init_xml: generatedXml[INIT],
        init_options: workspaceCode[INIT],
        init_code: await makeCode(code, workspaceCode, INIT, aidate),
      };
      data_mq5 = {
        name: name,
        mt5_init_xml: generatedXml[INIT],
        mt5_init_options: workspaceCode[INIT],
        mt5_init_code: await makeCodeMQ5(code, workspaceCode, INIT, aidate),
      };
    } else if (workspaceCode[ONBAR] && currentCodeType === ONBAR) {
      const code = generateCodeForXmlType(ONBAR);
      data = {
        name: name,
        bar_xml: generatedXml[ONBAR],
        bar_options: workspaceCode[ONBAR],
        bar_code: await makeCode(code, workspaceCode, ONBAR, aidate),
      };
      data_mq5 = {
        name: name,
        mt5_bar_xml: generatedXml[ONBAR],
        mt5_bar_options: workspaceCode[ONBAR],
        mt5_bar_code: await makeCodeMQ5(code, workspaceCode, ONBAR, aidate),
      };
    }

    const g_code = await makeGlobalCode(workspaceCode);
    const g_code_mq5 = await makeGlobalCodeMQ5(workspaceCode);
    const seprateCode = (code) => {
      if (code) {
        const regexMAHandle = /^MA_Handle.*;$/gm;
        const regexArraySetAsSeries =
          /ArraySetAsSeries\(MA_Array\d*,\s*true\);/g;
        const regexEventSetTimer = /EventSetTimer\(\d+\);/g;
        const regexRemoveLines =
          /LotSizeAutomatic\s*=\s*UseAutomaticMoneyManagement;\n|UseMartingale\s*=\s*UseIntelligentMartingale;\n/g;
        const regexAlert = /Alert\(.*?\)/g;
        const regexPriceFunctions = /i(High|Low|Open|Close)\(.*?\);/g;

        const maHandles = code.match(regexMAHandle) || [];
        const arraySets = code.match(regexArraySetAsSeries) || [];
        const eventSetTimers = code.match(regexEventSetTimer) || [];
        const removedLines = code.match(regexRemoveLines) || [];
        const removedAlert = code.match(regexAlert) || [];
        const removedPrice = code.match(regexPriceFunctions) || [];

        const stringWithoutMAHandleAndArraySet = code
          .replace(regexMAHandle, "")
          .replace(regexArraySetAsSeries, "")
          .replace(regexEventSetTimer, "")
          .replace(regexRemoveLines, "")
          .replace(removedAlert, "")
          .replace(/^\s+$/gm, "")
          .trim();

        const stringWithSelectedLines = [
          "",
          ...eventSetTimers,
          "",
          ...maHandles,
          "",
          ...arraySets,
          "",
          ...removedLines,
          "",
          removedAlert,
          "",
          ...removedPrice,
        ].join("\n");

        return {
          tickcode: stringWithoutMAHandleAndArraySet,
          initcode: stringWithSelectedLines,
        };
      }
    };

    if (workspaceCode[TICK] && currentCodeType === TICK) {
      data_mq5 = {
        ...data_mq5,
        mt5_global_code: g_code_mq5,
        mt5_tick_code: seprateCode(data_mq5?.mt5_tick_code).tickcode,
        mt5_init_code: seprateCode(data_mq5?.mt5_tick_code).initcode,
      };
    } else if (workspaceCode[ONBAR] && currentCodeType === ONBAR) {
      data_mq5 = {
        ...data_mq5,
        mt5_global_code: g_code_mq5,
        mt5_bar_code: seprateCode(data_mq5?.mt5_bar_code).tickcode,
        mt5_init_code: seprateCode(data_mq5?.mt5_bar_code).initcode,
      };
    }
    data = { ...data, global_code: g_code };
    data_mq5 = { ...data_mq5, mt5_global_code: g_code_mq5 };

    const accessToken = localStorage.getItem("token");
    props.postWorkspace({
      id: props.workspaceList.some((el) => el.name === name)
        ? currentRobotId
        : "",
      data: { ...data, ...data_mq5 },
      accessToken,
      callback: (status, info) => {
        if (status === ERROR) {
          setNameError(info);
          if (info === "Token is Expired") {
            setNameError("");
            setTimeout(() => {
              if (action === "download") {
                onSave("download", type);
              } else {
                onSave("save", "");
              }
            }, 1000);
          }
          return;
        }
        if (status === SUCCESS) {
          props.getAllWorkspaceList({
            accessToken: localStorage.getItem("token"),
          });
          setl(false);
          setModalSaveVisible(false);
          setCurrentName(info.name);
          setCurrentRobotId(info.id);
          message.success(`${info.name} successfully saved`);
          if (action === "download") {
            downloadCode(type);
          }
        }
      },
    });
  };

  /**
   * @param {Event} event onChange event
   * @description handles change in name for the workspace
   */
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  /**
   * @description handles on delete for the workspace
   */
  const onDelete = () => {
    const { accessToken } = props;

    props.deleteWorkspace({
      id: currentRobotId,
      accessToken,
      callback: (res, info) => {
        if (res === SUCCESS) {
          Blockly.getMainWorkspace().clear();
          props.getAllWorkspaceList({
            accessToken: localStorage.getItem("token"),
          });
          setCurrentName("");
          setCurrentRobotId("");
          setCurrentCodeType(TICK);
          setModalDeleteVisible(false);
          message.success(info);
        }
        if (res === ERROR) {
          if (info !== "Token is Expired") message.error(info);
        }
      },
    });
    resetAllStates();
  };

  // Unselect selected block everytime the drawer is closed
  useEffect(() => {
    if (openDrawer) return;

    const block = Blockly.getMainWorkspace().getBlockById(currentBlockId);
    if (block) block.unselect();
  }, [openDrawer]);

  useEffect(() => {
    if (!isEmpty(currentBlockCode)) {
      onUpdate();
    }
  }, [currentBlockCode]);

  /**
   * @description handles on update for the workspace
   */
  const onUpdate = () => {
    setCompleteBlockCode((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState[currentBlockId] = {
        timeStamp: completeBlockCode[currentBlockId]["timeStamp"],
        type: currentFilterType,
        code: JSON.parse(JSON.stringify(currentBlockCode)),
      };
      return newState;
    });
  };
  /**
   * @param {string} type contains type of file to download
   * @description handles on download actions
   */
  const onDownloadButton = (type) => {
    onSave("download", type);
    setdownloadFile("download");
    if (type === "ex4") {
      setloader((pre) => ({ ...pre, ex4: true }));
    }
    if (type === "mq4") {
      setloader((pre) => ({ ...pre, mq4: true }));
    }
    if (type === "ex5") {
      setloader((pre) => ({ ...pre, ex5: true }));
    }
    if (type === "mq5") {
      setloader((pre) => ({ ...pre, mq5: true }));
    }
  };

  const downloadCode = (type) => {
    props.saveFile({
      accessToken: localStorage.getItem("token"),
      name: currentName,
      data: {
        file_type:
          type === "ex5"
            ? "ex5"
            : type === "ex4"
            ? "ex4"
            : type === "mq4"
            ? "mq4"
            : "mq5",
        workspace_id: currentRobotId,
        global_code:
          type === "mq4" || type === "ex4"
            ? makeGlobalCode(workspaceCode)
            : makeGlobalCodeMQ5(workspaceCode),
      },
      type: type,
      callback: (status, info) => {
        setdownloadFile("");
        setloader((pre) => ({
          ...pre,
          ex4: false,
          mq4: false,
          ex5: false,
          mq5: false,
        }));
      },
    });
  };

  /**
   * @description toggle different workspaces
   */
  const toggleWorkspace = (event) => {
    setIsCodeChange(false);
    setModalUnsavedVisible(false);
    setCompleteBlockCode({});
    var value;
    if (event) {
      value = event;
    } else {
      value = TICK;
    }
    setCurrentCodeType(value);
    const { workspaceData } = props;
    Blockly.getMainWorkspace().clear();
    if (currentRobotId) {
      if (value === TICK && workspaceData.tick_xml) {
        setCompleteBlockCode(workspaceData.tick_options);
        const xml = Blockly.Xml.textToDom(workspaceData.tick_xml);
        Blockly.Xml.clearWorkspaceAndLoadFromXml(
          xml,
          Blockly.getMainWorkspace()
        );
        return;
      } else {
        if (value === INIT && workspaceData.init_xml) {
          setCompleteBlockCode(workspaceData.init_options);
          const xml = Blockly.Xml.textToDom(workspaceData.init_xml);
          Blockly.Xml.clearWorkspaceAndLoadFromXml(
            xml,
            Blockly.getMainWorkspace()
          );
          return;
        } else {
          if (value === ONBAR && workspaceData.bar_xml) {
            setCompleteBlockCode(workspaceData.bar_options);
            const xml = Blockly.Xml.textToDom(workspaceData.bar_xml);
            Blockly.Xml.clearWorkspaceAndLoadFromXml(
              xml,
              Blockly.getMainWorkspace()
            );
            return;
          }
        }
      }
    }
  };

  // updates workspace code whenever completeBlockCode
  // changes i.e. when drawer options are updated
  useEffect(() => {
    const tempCode = JSON.parse(JSON.stringify(workspaceCode));
    tempCode[currentCodeType] = JSON.parse(JSON.stringify(completeBlockCode));
    setWorkspaceCode(tempCode);
  }, [completeBlockCode]);

  // updates workspace code when loaded from the db
  // updates workspace xml when loaded from the db
  useEffect(() => {
    const { workspaceData } = props;
    let tempCode = {};
    let tempXml = {};

    if (workspaceData.tick_xml) {
      tempXml[TICK] = workspaceData.tick_xml;
      tempCode[TICK] = JSON.parse(JSON.stringify(workspaceData.tick_options));
    }
    if (workspaceData.init_xml) {
      tempXml[INIT] = workspaceData.init_xml;
      tempCode[INIT] = JSON.parse(JSON.stringify(workspaceData.init_options));
    }
    if (workspaceData.bar_xml) {
      tempXml[ONBAR] = workspaceData.bar_xml;
      tempCode[ONBAR] = JSON.parse(JSON.stringify(workspaceData.bar_options));
    }
    setGeneratedXml({ ...tempXml });
    setWorkspaceCode({ ...tempCode });
  }, [props.workspaceData]);

  /**
   * @description handles code type change (on init, on event)
   */
  const handleCodeTypeChange = (event) => {
    if (checkIfCodeChanged()) {
      setModalUnsavedVisible(true);
      setToogleValue(event);
      return;
    }
    toggleWorkspace(event);
  };

  /**
   * @description creates new workspace
   */
  const newWorkspace = () => {
    Blockly.getMainWorkspace().clear();
    resetAllStates();
    message.success(WORKSPACE_CREATED);
  };

  /**
   * @description resets all states
   */
  const resetAllStates = () => {
    setCurrentBlockId("");
    setCurrentBlockCode({});
    setCompleteBlockCode({});
    setCurrentFilterType("");
    setDeletedCodeState({});
    setWorkspaceCode({});

    setOpenDrawer(false);
    setDrawerHeader("");
    setCurrentRobotId("");

    setNameError("");
    setCurrentName("");
    setGeneratedXml("");
    setCurrentCodeType(TICK);

    setModalSaveVisible(false);
    setModalDeleteVisible(false);
    setModalUnsavedVisible(false);
    setIndicatorType("");
    setBarType("");
    setAlertType("");

    setIsDuplicated(false);
  };

  /**
   * @description handles on click save
   */
  const onSaveClick = () => {
    const { accessToken } = props;

    setNameError("");
    setModalSaveVisible(true);
    setName(currentRobotId ? currentName : "");
  };

  /**
   * @description check if code changed for the current workspace
   */
  const handleFilterDrawer = (updatedValve) => {
    setCurrentBlockCode(updatedValve);
    setIsCodeChange(true);
  };
  const checkIfCodeChanged = () => {
    if (isCodeChange) {
      return true;
    }
    const { init_xml, tick_xml, bar_xml } = props.workspaceData;
    if (currentName !== "" && generatedXml[currentCodeType]) {
      if (
        (currentCodeType === INIT &&
          init_xml?.length > 0 &&
          generatedXml[currentCodeType].length !== init_xml.length) ||
        (currentCodeType === ONBAR &&
          bar_xml?.length > 0 &&
          generatedXml[currentCodeType].length !== bar_xml.length) ||
        (currentCodeType === TICK &&
          tick_xml?.length > 0 &&
          generatedXml[currentCodeType].length !== tick_xml.length)
      ) {
        return true;
      } else if (
        (currentCodeType === INIT &&
          init_xml === "" &&
          generatedXml[currentCodeType] &&
          generatedXml[currentCodeType].length > XML_INITAL_LEN) ||
        (currentCodeType === ONBAR &&
          bar_xml === "" &&
          generatedXml[currentCodeType] &&
          generatedXml[currentCodeType].length > XML_INITAL_LEN) ||
        (currentCodeType === TICK &&
          tick_xml === "" &&
          generatedXml[currentCodeType] &&
          generatedXml[currentCodeType].length > XML_INITAL_LEN)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        generatedXml[currentCodeType] &&
        generatedXml[currentCodeType].length > XML_INITAL_LEN
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    resetAllStates();
  }, []);
const handledrawer=()=>{
    setOpenDrawer(!openDrawer)
}
  return (
    <>
      <Row className="">
        <div className="small_wcp">
          <div className="workspace-header">
            <div className="">
              <CPrompt
                when={!!checkIfCodeChanged()}
                title="please confirm"
                message="You have unsaved changes in workspace. Are you sure you want to leave this page?"
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onCancel={() => false}
              />
              <Select
                onChange={(event) => handleCodeTypeChange(event)}
                className="theme-text dropdown-wp"
                showArrow={true}
                value={currentCodeType}
              >
                {[TICK, INIT, ONBAR].map((data, index) => {
                  return (
                    <Option key={`${data}${index}`} id={index} value={data}>
                      {data}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div className=" text-center">
              <Select
                onChange={handleWorkspaceChange}
                className="theme-text dropdown-wp"
                showArrow={true}
                value={currentName.length <= 0 ? UNTITLED_ROBOT : currentName}
              >
                {props.workspaceList.map((item, index) => (
                  <Option key={`${item}${index}`} id={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className=" wcp_btn_group" style={{ textAlign: "center" }}>
            {currentRobotId && (
              <PlusOutlined
                onClick={newWorkspace}
                className="margin-right-10"
                style={{ fontSize: "20px" }}
              />
            )}
            {currentRobotId && (
              <DeleteOutlined
                onClick={() => setModalDeleteVisible(true)}
                className="margin-right-10"
                style={{ fontSize: "20px" }}
              />
            )}
            {checkIfCodeChanged() && (
              <Button
                type="default"
                className="button default-button margin-right-10 btns b-5"
                onClick={onSaveClick}
              >
                {SAVE}
              </Button>
            )}

            {currentName && props.payment && props.payment.mode === GOLD && (
              <button
                className="wcp_btn"
                onClick={() => onDownloadButton("mq4")}
                disabled={loader.mq4}
              >
                <div className="">
                  <img
                    src={require("../../assets/robotasset/wcp-download.png")}
                    alt=""
                  />
                </div>
                <span>{loader.mq4 ? LOADER : ".mq4 File"}</span>
              </button>
            )}
            {currentName && props.payment &&   (
              <button
                className="wcp_btn"
                onClick={() => onDownloadButton("ex4")}
                disabled={loader.ex4}
              >
                <div className="">
                  <img
                    src={require("../../assets/robotasset/wcp-download.png")}
                    alt=""
                  />
                </div>
                <span>{loader.ex4 ? LOADER : ".ex4 File "}</span>
              </button>
            )}
            {currentName && props.payment && props.payment.mode === GOLD && (
              <button
                className="wcp_btn"
                onClick={() => onDownloadButton("mq5")}
                disabled={loader.mq5}
              >
                <div className="">
                  <img
                    src={require("../../assets/robotasset/wcp-download.png")}
                    alt=""
                  />
                </div>
                <span>{loader.mq5 ? LOADER : ".mq5 File "}</span>
              </button>
            )}
            {currentName && props.payment &&   (
              <button
                className="wcp_btn"
                onClick={() => onDownloadButton("ex5")}
                disabled={loader.ex5}
              >
                <div className="">
                  <img
                    src={require("../../assets/robotasset/wcp-download.png")}
                    alt=""
                  />
                </div>
                <span>{loader.ex5 ? LOADER : ".ex5 File "}</span>
              </button>
            )}
          </div>
        </div>
        <Row align="top" className="main_wcp">
          <div className="workspace" id="blockly">
            <BlocklyEditor
              toolboxCategories={toolboxCategories}
              workspaceConfiguration={{
                scrollbars: false,
                trashcan: true,
                disable: false,
                comments: false,
                collapse: false,
                zoom: {
                  controls: true,
                  startScale: 1.0,
                  maxScale: 3,
                  minScale: 0.3,
                  scaleSpeed: 1.1,
                },
                // theme: lightTheme,
              }}
              wrapperDivClassName="fill-height "
              workspaceDidChange={workspaceDidChange}
            ></BlocklyEditor>
          </div>
        </Row>
        <div className="right_drawer">
          <div className="workspace-header text-center">
            <div>
              <CPrompt
                when={!!checkIfCodeChanged()}
                title="please confirm"
                message="You have unsaved changes in workspace. Are you sure you want to leave this page?"
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onCancel={() => false}
              />
              <Select
                onChange={(event) => handleCodeTypeChange(event)}
                className="theme-text dropdown-wp"
                showArrow={true}
                value={currentCodeType}
              >
                {[TICK, INIT, ONBAR].map((data, index) => {
                  return (
                    <Option key={`${data}${index}`} id={index} value={data}>
                      {data}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div>
              <Select
                onChange={handleWorkspaceChange}
                className="theme-text dropdown-wp"
                showArrow={true}
                value={currentName.length <= 0 ? UNTITLED_ROBOT : currentName}
              >
                {props.workspaceList.map((item, index) => (
                  <Option key={`${item}${index}`} id={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className=" wcp_btn_group" style={{ textAlign: "center" }}>
            {checkIfCodeChanged() && (
              <button
                type="default"
                className="wcp_btn"
                style={{ marginTop: "15px" }}
                onClick={onSaveClick}
              >
                <span>{SAVE}</span>
              </button>
            )}
            {currentRobotId && (
              <img
                onClick={newWorkspace}
                className="wcp-btn"
                src={require("../../assets/robotasset/cwp.png")}
                alt=""
              />
            )}
            {currentRobotId && (
              <img
                onClick={() => setModalDeleteVisible(true)}
                className="wcp-btn"
                src={require("../../assets/robotasset/dwp.png")}
                alt=""
              />
            )}
            {currentRobotId && <Divider />}

            {currentName && props.payment && props.payment.mode === GOLD && (
              <button
                className="wcp_btn"
                onClick={() => onDownloadButton("mq4")}
                disabled={loader.mq4}
              >
                <div className="">
                  <img
                    src={require("../../assets/robotasset/wcp-download.png")}
                    alt=""
                  />
                </div>
                <span>{loader.mq4 ? LOADER : ".mq4 File Download"}</span>
              </button>
            )}
            {currentName && props.payment  && (
              <button
                className="wcp_btn"
                onClick={() => onDownloadButton("ex4")}
                disabled={loader.ex4}
              >
                <div className="">
                  <img
                    src={require("../../assets/robotasset/wcp-download.png")}
                    alt=""
                  />
                </div>
                <span>{loader.ex4 ? LOADER : ".ex4 File Download"}</span>
              </button>
            )}
            {currentName && props.payment && props.payment.mode === GOLD && (
              <button
                className="wcp_btn"
                onClick={() => onDownloadButton("mq5")}
                disabled={loader.mq5}
              >
                <div className="">
                  <img
                    src={require("../../assets/robotasset/wcp-download.png")}
                    alt=""
                  />
                </div>
                <span>{loader.mq5 ? LOADER : ".mq5 File Download"}</span>
              </button>
            )}
            {currentName && props.payment  && (
              <button
                className="wcp_btn"
                onClick={() => onDownloadButton("ex5")}
                disabled={loader.ex5}
              >
                <div className="">
                  <img
                    src={require("../../assets/robotasset/wcp-download.png")}
                    alt=""
                  />
                </div>
                <span>{loader.ex5 ? LOADER : ".ex5 File Download"}</span>
              </button>
            )}
          </div>
        </div>
      </Row>
      {Object.values(DRAWER_FILTER_TYPE).includes(currentFilterType) && (
        <DrawerBlock
          title={drawerHeader ? drawerHeader : "Week"}
          onClose={handledrawer}
          visible={openDrawer}
        >
          {currentFilterType === DRAWER_FILTER_TYPE.WEEK && (
            <WeekDrawer
              setDrawerState={setOpenDrawer}
              tempBlockCode={tempBlockCode}
              currentBlockCode={currentBlockCode}
              onCodeChange={handleChange}
              onUpdate={onUpdate}
            />
          )}

          {currentFilterType === DRAWER_FILTER_TYPE.INDICATOR && (
            <IndicatorDrawer
              setDrawerState={setOpenDrawer}
              indicatorType={indicatorType}
              code={currentBlockCode}
              onCodeChange={handleFilterDrawer}
            />
          )}

          {currentFilterType === DRAWER_FILTER_TYPE.BAR && (
            <BarDrawer
              setDrawerState={setOpenDrawer}
              barType={barType}
              code={currentBlockCode}
              onCodeChange={handleFilterDrawer}
            />
          )}

          {(currentFilterType === DRAWER_FILTER_TYPE.BUY ||
            currentFilterType === DRAWER_FILTER_TYPE.SELL) && (
            <BuySellDrawer
              currentFilterType={currentFilterType}
              setDrawerState={setOpenDrawer}
              code={currentBlockCode}
              onCodeChange={handleFilterDrawer}
            />
          )}

          {currentFilterType === DRAWER_FILTER_TYPE.CONFIGURE && (
            <ConfigureDrawer
              currentFilterType={currentFilterType}
              setDrawerState={setOpenDrawer}
              code={currentBlockCode}
              onCodeChange={handleFilterDrawer}
            />
          )}
          {currentFilterType === DRAWER_FILTER_TYPE.CONFIGUREAI && (
            <ConfigureDrawerai
              aidate={aidate}
              setaidate={setaidate}
              currentFilterType={currentFilterType}
              setDrawerState={setOpenDrawer}
              code={currentBlockCode}
              onCodeChange={handleFilterDrawer}
            />
          )}

          {currentFilterType === DRAWER_FILTER_TYPE.TIME && (
            <TimeDrawer
              currentFilterType={currentFilterType}
              setDrawerState={setOpenDrawer}
              code={currentBlockCode}
              onCodeChange={handleFilterDrawer}
            />
          )}

          {currentFilterType === DRAWER_FILTER_TYPE.ALERT && (
            <AlertDrawer
              setDrawerState={setOpenDrawer}
              alertType={alertType}
              code={currentBlockCode}
              onCodeChange={handleFilterDrawer}
            />
          )}

          {currentFilterType === DRAWER_FILTER_TYPE.AUTO_MONEY && (
            <AutoMoneyDrawer
              currentFilterType={currentFilterType}
              setDrawerState={setOpenDrawer}
              code={currentBlockCode}
              onCodeChange={handleFilterDrawer}
            />
          )}

          {currentFilterType === DRAWER_FILTER_TYPE.MARTINGALE && (
            <MartingaleDrawer
              currentFilterType={currentFilterType}
              setDrawerState={setOpenDrawer}
              code={currentBlockCode}
              onCodeChange={handleFilterDrawer}
            />
          )}

          {currentFilterType === DRAWER_FILTER_TYPE.CUSTOM_CODE && (
            <CustomCodeDrawer
              currentFilterType={currentFilterType}
              setDrawerState={setOpenDrawer}
              code={currentBlockCode}
              onCodeChange={handleFilterDrawer}
            />
          )}
        </DrawerBlock>
      )}

      <Modal
        title={MODAL_HEADER_CONFIRMATION}
        style={{ top: "20%" }}
        visible={modalDeleteVisible}
        onCancel={() => setModalDeleteVisible(false)}
        footer={[
          <Button
            key="cancel"
            className="btns b-5"
            type="default"
            onClick={() => setModalDeleteVisible(false)}
          >
            {CANCEL}
          </Button>,
          <Button
            key="delete"
            className="btns b-5"
            type="primary"
            onClick={() => onDelete(false)}
          >
            {DELETE}
          </Button>,
        ]}
      >
        <p>{`${MODAL_DELETE_ROBOT} ${currentName} robot?`}</p>
      </Modal>

      <Modal
        title={MODAL_HEADER_UNSAVED}
        style={{ top: "20%" }}
        visible={modalUnsavedVisible}
        onCancel={() => setModalUnsavedVisible(false)}
        footer={[
          <Button
            key="cancel"
            type="primary"
            className="b-5"
            onClick={() => setModalUnsavedVisible(false)}
          >
            {CANCEL}
          </Button>,
          <Button
            key="save"
            type="primary"
            className="b-5"
            onClick={(event) => toggleWorkspace(toogleValue)}
          >
            {PROCEED_ANYWAY}
          </Button>,
        ]}
      >
        <p>{MODAL_HEADER_UNSAVED_CONTENT}</p>
      </Modal>

      <Modal
        visible={modalSaveVisible}
        // onOk={name?.length <= 0 ? '' : () => onSave('save', '')}
        // okText={SAVE}
        closable={true}
        style={{ top: "20%" }}
        onCancel={() => setModalSaveVisible(false)}
        footer={[
          <Button
            key="cancel"
            className="btns b-5"
            type="default"
            onClick={() => setModalSaveVisible(false)}
          >
            {CANCEL}
          </Button>,
          <Button
            key="save"
            className="btns b-5"
            type="primary"
            disabled={l}
            onClick={() => (name?.length <= 0 ? "" : onSave("save", ""))}
          >
            {l ? "Loading.." : SAVE}
          </Button>,
        ]}
      >
        <p className="label medium-text theme-text">{MODAL_HEADER_SAVE}</p>
        <Input
          onChange={handleNameChange}
          value={name}
          className="inputBox-style height-50 b-5"
          placeholder={UNTITLED_ROBOT}
        />
        {nameError?.length > 0 && (
          <div className="error-message padding-20">{nameError}</div>
        )}
      </Modal>
    </>
  );
}

const mapDispatchToProps = {
  postWorkspace: postWorkspace,
  getWorkspace: getWorkspace,
  getAllWorkspaceList: getAllWorkspaceList,
  deleteWorkspace: deleteWorkspace,
  saveFile: saveFile,
  GlobalStates: GlobalStates,
};
const mapStateToProps = ({
  workspaceReducer: { workspaceData, workspaceList },
  userReducer: { accessToken, payment, subscription },
}) => ({
  subscription,
  workspaceData,
  accessToken,
  workspaceList,
  payment,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceWindow);
  